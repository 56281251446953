import { WorkspaceConfiguration } from '../workspaceConfiguration';
declare var IS_ON_PREM: boolean;

export function hasFeatureCanary(
	workspaceConfiguration: WorkspaceConfiguration,
	canary: string,
	featureCanariesAvailable: string[] = featureCanariesOnPrem
) {
	return (
		isFeatureAvailableInCanaryList(workspaceConfiguration, canary) ||
		isFeatureEnabledForOnPrem(workspaceConfiguration, canary, featureCanariesAvailable)
	);
}

const isFeatureAvailableInCanaryList = (
	workspaceConfiguration: WorkspaceConfiguration,
	canaryName: string
) => {
	return !!workspaceConfiguration?.featureCanaries?.canary.some(
		({ name }) => name === canaryName
	);
};

function isFeatureEnabledForOnPrem(
	workspaceConfiguration: WorkspaceConfiguration,
	featureFlagName: string,
	featureCanariesAvailable: string[]
) {
	if (!IS_ON_PREM) {
		return false;
	}

	const isFeatureEnabledForStorefront = (featureName: String) => {
		return !!workspaceConfiguration?.featureToggles?.feature?.some(
			({ name, enabled }) => name === featureName && enabled === 'True'
		);
	};

	// Check if the feature is enabled in Storefront with the given name
	if (isFeatureEnabledForStorefront(featureFlagName)) {
		return true;
	}

	// else check for the status with translated name...
	const onPremFeatureName = cloudToOnpremFeatureNameMap.get(featureFlagName);
	return onPremFeatureName
		? isFeatureEnabledForStorefront(onPremFeatureName)
		: featureCanariesAvailable.includes(featureFlagName);
}

//The Map contains the cloud feature flag name as key, and corresponding storefront feature flag name as value.
const cloudToOnpremFeatureNameMap = new Map([
	['WSHELP301EnableUserDisplayName', 'Citrix.StoreFront.WorkspaceUI.EnableUsername'],
	[
		'WSUI4148IsAppProtectionEnabled',
		'Citrix.StoreFront.AppProtectionPolicy.HybridLaunch',
	],
]);

const featureCanariesOnPrem: string[] = [
	'WSUI7054FilesIntegrationDisabled',
	'WSUI6282FuzzySearch',
	'WSHELP301EnableUserDisplayName',
	'WSUI8335DetectClientBeforeAuthentication',
	'WSUI9765EnableOpenCitrixForLinux',
	'WSUI9765EnableOpenCitrixForChromeOS',
	'WSUI8552EnableSynchronousAppShell',
	'WSHELP417UseFixForDesktopAvailability',
	'WSUI9333AllowOnlyJsonIca',
	'WSUI9628ForceEnableNewUI',
	'WSUI9293CustomLinkBanner',
	'WSUI9437ActivityManagerRefactoring',
	'WSUI10311AllowWorkspaceControl',
	'WSUI7246EnableMachinePowerState',
	'WSUI10305AllowBlueTheme',
];
