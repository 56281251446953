import { post } from '@citrite/http';
import {
	browser,
	hasFeatureCanary,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import * as Cookie from 'js-cookie';
import { logError } from 'remoteLogging';
import URI from 'urijs';
import { environment, LaunchProgressHandler } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	checkLaunchPreference,
	leaseLaunchForHTML5,
} from 'Environment/Html5/Html5LeaseLaunch';
import { HTML5Client } from 'Environment/launchResource/html5Client';
import { LaunchStatus } from 'Environment/launchResource/launchResource';
import { launchSaasAppUsingNative } from 'Environment/launchResource/launchSaasWithNative';
import {
	isAppShortcutLaunch,
	nativeWorkspaceScheme,
	urlSafeBase64Encode,
} from 'Environment/launchResource/launchUtil';
import { gacsTicketFetcher } from 'GlobalAppConfigService';
import { detectLanguage } from 'javascript/sf/detectLanguage';
import { isHTML5ShieldEnabled } from 'LeaseWorkflow/ShieldModule';
import { LaunchPreference, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { platformsWhichNeedWindowOpen } from 'Workspace/SchemeCallHandler/schemeCallHandler';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { saasAppLaunchAnalyticsReporter } from 'Workspace/TelemetryEvents/saasLaunch/createSaasAppLaunchAnalyticsReporter';
import {
	ChromeAppLaunchOptions,
	postICADataErrorToChromeApp,
	postICADataToChromeApp,
} from './chromeApp';
import {
	doesNativeSupportSaasLaunch,
	getLaunchMethod,
	shouldUseNativeForSaasLaunch,
} from './clientManager';
import { launchMethod } from './constants';
import { getCurrentPlatform, isCitrixChromeApp } from './device';
import { getLaunchStatus, launchHtml5 } from './resourcesClient';

declare global {
	interface Window {
		html5LaunchData: any;
	}
}

export function resourceLaunch(
	resource: Resource,
	config: WorkspaceConfiguration,
	isAutoLaunch: boolean,
	chromeAppOptions: ChromeAppLaunchOptions,
	jsonParams: object,
	targetWindow?: Window,
	operationId?: string,
	launchProgressHandler?: LaunchProgressHandler
) {
	if (resource.content) {
		trackEvent('SaaSAppLaunch');
		if (shouldUseNativeForSaasLaunch(config) && doesNativeSupportSaasLaunch()) {
			trackAnalyticsEvent(
				saasAppLaunchAnalyticsReporter.getNativeLaunchEvent(resource.id)
			);
			launchSaasAppUsingNative(resource);
			return Promise.resolve();
		} else if (
			hasFeatureCanary(config, FeatureFlag.PreLaunchChanges) &&
			resource.prelaunchserviceurl
		) {
			return updatedPreLaunch(config, resource);
		} else if (resource.prelaunchurl) {
			return preLaunch(resource);
		} else {
			environment.openWindow(resource.content, true);
			return Promise.resolve();
		}
	}

	// For ICA file download with IE in the internet zone, the download is attempted immediately to preserve the
	// association between the user's click and the download. Attempting the download in an AJAX handler loses this
	// association and results in IE displaying an infobar. Delay-launched resources cannot be auto-launched in IE8
	// and must be clicked again once they are ready to launch (as depicted by a 'play' icon).

	const method = getLaunchMethod(config);
	if (
		(browser.isInternetExplorer() && method === launchMethod.icaFile && !isAutoLaunch) ||
		(method === launchMethod.html5 &&
			!html5SingleTabLaunch(method, config) &&
			!isCitrixChromeApp())
	) {
		const isValid = launchProgressHandler?.validateLaunchNextStep(
			LaunchStatus.PerformingLaunch
		);
		if (!isValid) {
			return Promise.resolve();
		}
		return performLaunch(
			resource,
			config,
			null,
			null,
			jsonParams,
			targetWindow,
			operationId,
			launchProgressHandler.launchCancellationToken
		);
	} else {
		return prepareLaunch(
			resource,
			config,
			chromeAppOptions,
			jsonParams,
			operationId,
			launchProgressHandler
		);
	}
}

function html5SingleTabLaunch(method: launchMethod, config: WorkspaceConfiguration) {
	return (
		method === launchMethod.html5 &&
		(config.pluginAssistant.html5.singleTabLaunch === 'true' || isAppShortcutLaunch())
	);
}

async function updatedPreLaunch(config: WorkspaceConfiguration, resource: Resource) {
	post<any>(resource.recordlaunchurl).catch(error => {
		logError(error, { customMessage: 'record launch failed with exception' });
	});

	const client = environment.createSSOProxyClient(config.authManager.proxyUrl);
	const response = client
		.postWithFullResponse<any>(resource.prelaunchserviceurl, resource.content, {
			headers: { 'Citrix-App-PreLaunch': true },
		})
		.then(async result => {
			const status = result.status;
			if (status === 200 && result.data) {
				const uri = new URI(result.data);
				window.open(uri.toString());
				return Promise.resolve();
			}
			return handleFailure(resource);
		});
	return response;
}

function preLaunch(resource: Resource) {
	return post<any>(resource.prelaunchurl).then(data => {
		const status = data.status;

		if (status === 'success' && data.url) {
			const uri = new URI(data.url);
			const csrfToken = Cookie.get('CsrfToken');
			if (csrfToken) {
				uri.addSearch('CsrfToken', csrfToken).addSearch('IsUsingHttps', 'Yes');
			}
			window.open(uri.toString());
			return Promise.resolve();
		}
		return handleFailure(resource);
	});
}

function handleFailure(resource: Resource) {
	trackAnalyticsEvent(AppsUserEventPayload.launchFailure(resource.type));
	return Promise.reject(new Error('Resource pre-launch failed'));
}

function performLaunch(
	resource: Resource,
	config: WorkspaceConfiguration,
	chromeAppOptions: ChromeAppLaunchOptions,
	protocolHandlerData: any,
	jsonParams: object,
	targetWindow?: Window,
	operationId?: string,
	launchCancellationToken?: string
) {
	const method = getLaunchMethod(config);

	let icaFileURL = environment.store.baseUri + resource.launchurl;
	const csrfToken = Cookie.get('CsrfToken');
	const currentTime = new Date().getTime();

	if (csrfToken != null) {
		icaFileURL = new URI(icaFileURL)
			.addSearch('CsrfToken', csrfToken)
			.addSearch('IsUsingHttps', 'Yes')
			.toString();
	}

	if (jsonParams) {
		icaFileURL = new URI(icaFileURL).addSearch(jsonParams).toString();
	}

	switch (method) {
		case launchMethod.html5:
			trackEvent('HTML5AppLaunch');
			const html5LaunchID = currentTime;
			let url =
				environment.store.baseUri +
				config.pluginAssistant.html5.launchURL +
				'?launchid=' +
				html5LaunchID;
			const lang = detectLanguage();
			let handle: Window = null;

			if (!isCitrixChromeApp()) {
				if (html5SingleTabLaunch(method, config)) {
					let launchUrl = environment.store.baseUri + resource.launchurl;
					launchUrl = new URI(launchUrl).addSearch(jsonParams).toString();
					url =
						url +
						'#launchurl=' +
						encodeURIComponent(launchUrl) +
						'&iconurl=' +
						encodeURIComponent(getIcon(resource).url) +
						'&clientpreferences=' +
						encodeURIComponent(config.pluginAssistant.html5.preferences) +
						'&resourcename=' +
						encodeURIComponent(resource.displayNameDesktopTitle || resource.encodedName) +
						'&resourcetype=' +
						(resource.isdesktop ? 'desktop' : 'app') +
						'&UILocale=' +
						lang;
					window.name = csrfToken;
					(window.location as any) = url;
					return Promise.resolve();
				} else {
					// Setting the fragment identifier to specify the communication type for the postMessage, so that html5client can identify that posMessage is supported
					url = url + '#comtype=message';
					// This call is a special one for the HTML 5 launch that starts the HTML engine.
					// It is not the ICA file request. That will have already occurred and the ICA contents will have been cached.
					if (targetWindow) {
						targetWindow.location.assign(url);
						handle = targetWindow;
					} else {
						handle = window.open(url);
					}

					if (!handle) {
						//Users that have not disabled pop up blocker on auto launch will fail to open window
						if (config.userInterface.autoLaunchDesktop === 'true') {
							trackEvent('AutoLaunchFailedPopUp');
						}
						return Promise.reject(new Error('No target window for HTML5 launch'));
					}
				}
			}

			const html5Client = new HTML5Client(handle);
			html5Client.startListening();

			return launchHtml5({
				resource,
				html5Client,
				chromeAppOptions,
				jsonParams,
				operationId,
				launchCancellationToken,
			})
				.then(data => {
					const icaData = parseIcaData(data);
					icaData['IconUrl'] = getIcon(resource).url;
					icaData['clientPreferences'] = config.pluginAssistant.html5.preferences;
					icaData['UILocale'] = lang;

					const imgObj = new Image();

					const startSession = () => {
						if (isCitrixChromeApp()) {
							postICADataToChromeApp(icaData, resource, chromeAppOptions);
						} else {
							html5Client.sendSuccess(icaData, html5LaunchID);
						}
					};

					imgObj.onload = function () {
						const drawingCanvas = document.createElement('canvas');
						const context = drawingCanvas.getContext('2d');
						drawingCanvas.height = imgObj.height;
						drawingCanvas.width = imgObj.width;
						context.drawImage(imgObj, 0, 0);
						const dataURL = drawingCanvas.toDataURL();
						icaData['IconUrl'] = dataURL;
						startSession();
					};
					imgObj.onerror = () => startSession();
					imgObj.src = getIcon(resource).url;
				})
				.catch(err => {
					if (
						isHTML5ShieldEnabled(config) &&
						checkLaunchPreference(resource, LaunchPreference.IcaFallbackToLease)
					) {
						return leaseLaunchForHTML5({
							resource,
							workspaceConfiguration: config,
							preferLeaseLaunch: false,
							targetWindow: handle,
						});
					} else {
						html5Client.sendError(err);
						return Promise.reject(err);
					}
				})
				.finally(() => {
					html5Client.stopListening();
				});
		case launchMethod.icaFile:
			trackEvent('ICAFileAppLaunch');
			return environment.launchViaIcaUrl(resource, config, icaFileURL, jsonParams);
		case launchMethod.protocolHandler:
			trackEvent('ProtocolHandlerAppLaunch');
			launchProtocolHandler({
				action: 'launch',
				url: protocolHandlerData.fileFetchUrl,
				staTicket: protocolHandlerData.staTicket,
				serverProtocolVersion: protocolHandlerData.serverProtocolVersion,
				ticket: protocolHandlerData.ticket,
			});
			break;
	}

	return Promise.resolve();
}

export interface ProtocolHandlerLaunchParams {
	action: 'launch' | 'detect';
	url: string;
	staTicket: string;
	serverProtocolVersion: string;
	ticket: string;
}

let iframe: HTMLIFrameElement;
export async function launchProtocolHandler(launchParams: ProtocolHandlerLaunchParams) {
	const transport = launchParams.url.indexOf('https') === 0 ? 'https' : 'http';
	let protocolHandlerUrl = launchParams.url.replace(/^https?:/, nativeWorkspaceScheme);
	let staParam = '';

	if (launchParams.staTicket) {
		staParam = `&staTicket=${encodeURIComponent(launchParams.staTicket)}`;
	}

	const gacsParams =
		launchParams.action === 'launch'
			? (await gacsTicketFetcher.getGacsParams()) || ''
			: '';

	const paramBlock = `action=${
		launchParams.action
	}&serverProtocolVersion=${encodeURIComponent(
		launchParams.serverProtocolVersion
	)}&transport=${encodeURIComponent(transport)}&ticket=${encodeURIComponent(
		launchParams.ticket
	)}${staParam}${gacsParams}`;

	protocolHandlerUrl = protocolHandlerUrl + '/' + urlSafeBase64Encode(paramBlock);

	if (platformsWhichNeedWindowOpen.includes(getCurrentPlatform())) {
		window.open(protocolHandlerUrl);
	} else {
		if (!iframe) {
			iframe = document.createElement('iframe');
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
		}
		iframe.src = protocolHandlerUrl;
	}
}

export function handleLaunchStatus(
	contents: string,
	config: WorkspaceConfiguration,
	resource: Resource,
	jsonParams: object
) {
	let errorId = null;
	let pollTimeoutStr = null;
	let status = 'failure';
	const json = JSON.parse(contents);

	if (json) {
		status = json.status;
		pollTimeoutStr = json.pollTimeout;
		errorId = json.errorId;
	}

	if (status === 'failure') {
		launchFailed(resource, errorId);
	} else if (status === 'retry') {
		const pollTimeout = parseInt(pollTimeoutStr, 10) * 1000;
		setTimeout(function () {
			prepareLaunch(resource, config, null, jsonParams, undefined, {
				validateLaunchNextStep: () => true,
			});
		}, pollTimeout);
	}
}

export function parseIcaData(data: string) {
	/*
	 *  =================================================================
	 *  [Encoding]
	 *  InputEncoding=UTF8

	 *  [WFClient]
	 *  ClientName=Tester1
		TWIMode=On

	 *  [ApplicationServers]
	 *  Notepad=

	 *  [Notepad]
	 *  TWIMode=Off

	 *  [Paint]
	 *  ClientName=Tester2
	 *  TWIMode=Off
	 *  =================================================================

	 *  Here we want to set the TWIMode special for notepad and TWIMode/ClientName for paint. Now we launch the application Notepad,
	 *  if we use the old logic, it will always get the last key/value. As ClientName=Tester2, TWIMode=Off under the paint section. It is wrong.

	 *  The fix logic is, we try to get the ClientName/TWIMode under the lanuched application section, here is [Notepad].
	 *  But the ClientName doesn't exist under [Notepad] section. For this scene we will get the first ClientName key/value,
	 *  here is under [WFClient] section. So the key/value we will get and apply to notepad is
	 *  ClientName=Tester2, TWIMode=Off.

	 *  alreadyProcessedKeys is an array that indicate does a key already been parsed.
	 *  appSection is used to store the launched application section. eg: [Notepad],[Paint]
	 *  sectionReg is used to match the section line in ICA file.
	 */
	const dataArr = data.split(/\r?\n/);
	const icaData = {};
	const alreadyProcessedKeys = {};
	let currentSection;
	let appSection;
	const sectionReg = new RegExp(/^\s*(\[.*\])\s*$/);

	for (let i = 0; i < dataArr.length; i++) {
		const sectionMatch = sectionReg.exec(dataArr[i]);
		if (sectionMatch) {
			currentSection = sectionMatch[1];
		}

		const nameValue = dataArr[i].split('=');
		if (nameValue.length > 1) {
			const key = nameValue[0];

			let constructValue = nameValue[1];
			for (let j = 2; j < nameValue.length; j++) {
				constructValue = constructValue + '=' + nameValue[j];
			}
			const value = constructValue;

			if (currentSection === '[ApplicationServers]') {
				appSection = '[' + key + ']';
			}
			if (!alreadyProcessedKeys[key] || currentSection === appSection) {
				icaData[key] = value;
				alreadyProcessedKeys[key] = 1;
			}
		}
		/*
		 * This is required as LaunchReference will contain '=' as well.
		 * The above split('=',2) will not provide with the complete LaunchReference.
		 * Ideally, something like the following should be used generically as well
		 * since there can be other variables with '=' character as part of the value
		 */
		if (nameValue[0] === 'LaunchReference') {
			const index = dataArr[i].indexOf('=');
			const value = dataArr[i].substr(index + 1);
			icaData[nameValue[0]] = value;
		}
	}
	return icaData;
}

function prepareLaunch(
	resource: Resource,
	config: WorkspaceConfiguration,
	chromeAppOptions: ChromeAppLaunchOptions,
	jsonParams: object,
	operationId?: string,
	launchProgressHandler?: LaunchProgressHandler
) {
	const method = getLaunchMethod(config);
	const createFileFetchTicket = method === launchMethod.protocolHandler;
	const { validateLaunchNextStep, launchCancellationToken } = launchProgressHandler ?? {};
	let isValid = validateLaunchNextStep(LaunchStatus.CheckingAvailability);
	if (!isValid) {
		return Promise.resolve();
	}
	return getLaunchStatus(
		resource,
		createFileFetchTicket,
		jsonParams,
		false,
		operationId,
		launchCancellationToken
	).then(data => {
		switch (data.status) {
			case 'success':
				const protocolHandlerData: any = {};

				if (browser.isInternetExplorer()) {
					return Promise.resolve();
				} else {
					if (method === launchMethod.protocolHandler) {
						protocolHandlerData.ticket = data.fileFetchTicket;
						protocolHandlerData.staTicket = data.fileFetchStaTicket;
						protocolHandlerData.fileFetchUrl = data.fileFetchUrl;
						protocolHandlerData.serverProtocolVersion = data.serverProtocolVersion;
					}
					isValid = validateLaunchNextStep(LaunchStatus.PerformingLaunch);
					if (!isValid) {
						return Promise.resolve();
					}
					return performLaunch(
						resource,
						config,
						chromeAppOptions,
						protocolHandlerData,
						jsonParams,
						undefined,
						operationId,
						launchCancellationToken
					);
				}
			case 'retry':
				isValid = validateLaunchNextStep(LaunchStatus.PoweringOn);
				if (!isValid) {
					return Promise.resolve();
				}
				return new Promise<void>(resolve => {
					setTimeout(function () {
						resolve(
							prepareLaunch(
								resource,
								config,
								chromeAppOptions,
								jsonParams,
								operationId,
								launchProgressHandler
							)
						);
					}, data.pollTimeout * 1000);
				});
			case 'failure':
			default:
				return Promise.reject(
					launchFailed(
						resource,
						data.errorId || 'Launch failure error id is unknown',
						chromeAppOptions
					)
				);
		}
	});
}

export function launchFailed(
	resource: Resource,
	errorId: string,
	chromeAppOptions?: ChromeAppLaunchOptions
) {
	if (isCitrixChromeApp() && chromeAppOptions) {
		postICADataErrorToChromeApp(errorId, resource, chromeAppOptions.uniqueIdentifier);
	}
	const error = new Error(errorId);
	error.name = 'CvadLaunchFailure';
	return error;
}

export function getIcon(resource: Resource) {
	const isDataUri =
		resource.iconurl && resource.iconurl.substring(0, 5).toLowerCase() === 'data:';
	if (isDataUri) {
		return { url: resource.iconurl, cssClass: '' };
	}

	return { url: resource.iconurl };
}
