import React, { useEffect, useState } from 'react';
import { t } from '@citrite/translate';
import {
	ArrowDownIcon,
	Button,
	layout,
	MobileActionSheet,
	MobileActionSheetItem,
	palette,
	SecondaryNavigation,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { trackAnalyticsEvent } from 'analytics';
import { first } from 'lodash';
import { useFormFactor } from 'App/Header/useFormFactor';
import { equalsIgnoreCase, getCategories } from 'App/Screen/util';
import { useBranding } from 'Configuration/withBranding';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { EventFilter } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const StyledArrowDownIcon = styled(ArrowDownIcon)`
	margin-left: ${layout.tinySpace};
	margin-bottom: -2px;
`;
const StyledDiv = styled.div`
	padding-bottom: ${layout.tinySpace};
`;

const moreContainerStyles = css`
	background-color: ${palette.grey100};
`;
const onlyRightMargin = css`
	margin-left: 0 !important;
	margin-right: ${layout.mediumSpace};
	margin-bottom: ${layout.mediumSpace};
`;

const StyledPrimaryNavigationButton = styled(Button.NavigationButtonPrimary)`
	${onlyRightMargin};
`;
const StyledSecondaryNavigationButton = styled(Button.NavigationButtonSecondary)`
	${onlyRightMargin}
`;

export interface Props extends WithModalsProps {
	selectedCategory: string;
	chooseCategory: React.Dispatch<React.SetStateAction<string>>;
	setSubCategoryPath?: React.Dispatch<React.SetStateAction<any[]>>;
}

const _CategoryList = ({
	selectedCategory,
	chooseCategory,
	showModal,
	setSubCategoryPath,
}: Props) => {
	const branding = useBranding();
	const { value: resourceContext } = useLoadableResourceContext();
	const [categories, setCategories] = useState(getCategories(resourceContext.resources));
	const { isLargeFormFactor } = useFormFactor();
	const isOffWhiteBackground = useFeatureCanary(FeatureFlag.EnableOffWhiteBackground);
	const selectCategory = (category: string) => {
		trackAnalyticsEvent(AppsUserEventPayload.filtering(EventFilter.Categories));
		setCategoryInfo(category);
	};

	useEffect(() => {
		setCategories(getCategories(resourceContext.resources));
	}, [resourceContext]);

	useEffect(() => {
		if (!selectedCategory && categories.length) {
			setCategoryInfo(first(categories));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories, selectedCategory, chooseCategory]);

	const categoryButtons = (categoriesList: string[]) => {
		const isSelectedCategory = (category: string) =>
			equalsIgnoreCase(selectedCategory, category);
		return categoriesList.map((category: string) => {
			const CategoryButton = isSelectedCategory(category)
				? StyledPrimaryNavigationButton
				: StyledSecondaryNavigationButton;
			return (
				<CategoryButton
					key={category}
					onClick={() => selectCategory(category)}
					backgroundColor={
						isSelectedCategory(category) ? branding.styles.AccentColor : palette.white
					}
				>
					{category}
				</CategoryButton>
			);
		});
	};
	/**
	 * The action sheet items would contain all the categories except 'All' which would be shown
	 * as a separate button. This is the reason we are slicing here for the first category item.
	 */
	const getSheetCategories = (): MobileActionSheetItem[] =>
		categories.slice(1).map((category: string) => ({
			title: category,
			onClick: () => selectCategory(category),
		}));

	const setCategoryInfo = (category: string) => {
		chooseCategory(category);
		setSubCategoryPath([category]);
	};

	const smallFormCategoriesList = () => {
		const firstCategory = first(categories);
		let OtherCategoriesButton = StyledSecondaryNavigationButton;
		let otherCategoriesButtonLabel = t('Workspace:categories');
		let iconColor = branding.styles.AccentColor;
		if (selectedCategory !== firstCategory) {
			OtherCategoriesButton = StyledPrimaryNavigationButton;
			otherCategoriesButtonLabel = selectedCategory;
			iconColor = palette.white;
		}

		const categoriesList = categoryButtons([firstCategory]);
		categoriesList.push(
			<OtherCategoriesButton
				key={otherCategoriesButtonLabel}
				onClick={() =>
					showModal(
						<MobileActionSheet menuItems={getSheetCategories()} closeOnOverlayClick />
					)
				}
				backgroundColor={branding.styles.AccentColor}
			>
				{otherCategoriesButtonLabel}
				<StyledArrowDownIcon color={iconColor} size={15} />
			</OtherCategoriesButton>
		);
		return categoriesList;
	};

	return (
		<StyledDiv data-testid="application-categories">
			{isLargeFormFactor ? (
				<SecondaryNavigation
					activeTab={selectedCategory}
					onChange={selectCategory}
					tabsArray={categories}
					activeTabColor={branding.styles.AccentColor}
					moreContainerStyle={isOffWhiteBackground && moreContainerStyles}
				/>
			) : (
				<Button.Group>
					{!!categories.length && selectedCategory && smallFormCategoriesList()}
				</Button.Group>
			)}
		</StyledDiv>
	);
};

export const CategoryList = withModals(_CategoryList);
