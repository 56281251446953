import { useEffect, useState } from 'react';
import { first } from 'lodash';
import { PowerState } from 'App/Activity/Network/ActionsUtil';
import {
	Machine,
	MachineData,
	MachineError,
	Session,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { isStaleMachineData } from 'App/Activity/Utility';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const validPowerStates = [
	PowerState.ON,
	PowerState.OFF,
	PowerState.SUSPENDED,
	PowerState.UNKNOWN,
	PowerState.LOADING,
];

const getLatestMachineData = (
	machineDataFromMachineApi: MachineData,
	machineDataFromSessionApi: MachineData
) => {
	let validMachineData = machineDataFromMachineApi;

	if (
		!validMachineData ||
		(machineDataFromSessionApi?.lastUpdatedTime &&
			machineDataFromMachineApi?.lastUpdatedTime <
				machineDataFromSessionApi.lastUpdatedTime)
	) {
		validMachineData = machineDataFromSessionApi;
	}

	if (isStaleMachineData(validMachineData?.lastUpdatedTime)) {
		validMachineData = null;
	}
	return validMachineData;
};

const getResourceState = (
	resource: Resource,
	machines: (Machine | MachineError)[],
	sessions: Session[]
) => {
	const machineDataFromMachineApi = (
		machines.find(({ resourceId }) => resourceId === resource.id) as Machine
	)?.machineData;
	const machineDataFromSessionApi = sessions.find(
		session => first(session.applications)?.resource?.id === resource.id
	)?.machineData;

	const latestMachineData = getLatestMachineData(
		machineDataFromMachineApi,
		machineDataFromSessionApi
	);

	return {
		powerState: latestMachineData?.powerState || PowerState.UNKNOWN,
		latestTimeStamp: latestMachineData?.lastUpdatedTime || null,
	};
};

export const useResourceState = (resource: Resource) => {
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);
	const context = useResourceManagerContext();
	const [state, setState] = useState<PowerState>(PowerState.NOT_SUPPORTED);
	const [lastUpdatedTime, setLastUpdatedTime] = useState<string | null>(null);

	useEffect(() => {
		let newState: PowerState;
		if (
			!resource.canquerymachinestate ||
			!resource.isdesktop ||
			!isMachinePowerStateEnabled
		) {
			newState = PowerState.NOT_SUPPORTED;
			setLastUpdatedTime(null);
		} else if (
			context?.machineStatus?.loading ||
			context?.localSessions?.loading ||
			context?.remoteSessions?.loading ||
			context?.hibernatedSessions?.loading
		) {
			newState = PowerState.LOADING;
			setLastUpdatedTime(null);
		} else {
			const machineData = getResourceState(
				resource,
				context?.machineStatus?.machineData,
				[
					...context?.localSessions?.sessions,
					...context?.remoteSessions?.sessions,
					...context?.hibernatedSessions?.sessions,
				]
			);
			newState = machineData?.powerState;
			setLastUpdatedTime(machineData?.latestTimeStamp);
		}
		if (!validPowerStates.includes(newState)) {
			newState = PowerState.NOT_SUPPORTED;
		}
		setState(newState);
	}, [
		context?.machineStatus,
		context?.localSessions,
		context?.remoteSessions,
		context?.hibernatedSessions,
		resource,
		isMachinePowerStateEnabled,
	]);

	return { state, lastUpdatedTime };
};
