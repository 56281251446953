import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import {
	canFetchMachinesNow,
	clearMachineDataFetchTime,
	fetchMachinesFromNetwork,
	getQueryableMachineIds,
	MACHINE_FETCH_CONFIG,
} from 'App/Activity/MachineUtils';
import { Machine, MachineError } from 'App/Activity/ResourceManagerContext';
import { useVisibility } from 'App/Activity/useVisibility';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

interface MachineStatusState {
	loading: boolean;
	machineData: (Machine | MachineError)[];
}

export const useMachineStatus = (): MachineStatusState => {
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);
	const { workspaceConfiguration } = useConfigurationContext();
	const { hasLoggedIn } = useUserContext();
	const {
		value: { resources },
	} = useLoadableResourceContext();

	const [loading, setLoading] = useState(false);
	const [machines, setMachines] = useState<(Machine | MachineError)[]>([]);

	const queryableMachineIds = useMemo(
		() => getQueryableMachineIds(resources),
		[resources]
	);

	const isVisible = useVisibility();
	const machineListEndpoint = useMemo(
		() => workspaceConfiguration?.storeProxy?.machinesProxy?.listMachinesURL || '',
		[workspaceConfiguration]
	);

	const fetchMachines = useCallback(
		async (url: string, desktopIds: string[], forceNetworkCall = false) => {
			if (!isVisible) {
				clearMachineDataFetchTime();
			} else if (forceNetworkCall || canFetchMachinesNow()) {
				setLoading(true);
				try {
					const machineData = await fetchMachinesFromNetwork(
						url,
						desktopIds,
						hasLoggedIn
					);
					setMachines(machineData);
				} finally {
					setLoading(false);
				}
			}
		},
		[isVisible, hasLoggedIn]
	);

	const debouncedFetchMachines = useMemo(() => {
		return debounce(fetchMachines, MACHINE_FETCH_CONFIG.debounceTime, {
			leading: true,
			trailing: false,
		});
	}, [fetchMachines]);

	useEffect(() => {
		if (
			!isMachinePowerStateEnabled ||
			!isVisible ||
			!queryableMachineIds?.length ||
			!machineListEndpoint
		) {
			return () => {};
		}

		const interval = setInterval(() => {
			debouncedFetchMachines(machineListEndpoint, queryableMachineIds);
		}, MACHINE_FETCH_CONFIG.pollingInterval);

		return () => clearInterval(interval);
	}, [
		isMachinePowerStateEnabled,
		queryableMachineIds,
		machineListEndpoint,
		isVisible,
		debouncedFetchMachines,
	]);

	useEffect(() => {
		if (
			isMachinePowerStateEnabled &&
			queryableMachineIds?.length > 0 &&
			machineListEndpoint
		) {
			debouncedFetchMachines(machineListEndpoint, queryableMachineIds, true);
		}
	}, [
		isMachinePowerStateEnabled,
		debouncedFetchMachines,
		queryableMachineIds,
		machineListEndpoint,
	]);

	return { loading, machineData: machines };
};
