import { fetchMachineList } from 'App/Activity/Network/MachineList';
import { Machine, MachineError } from 'App/Activity/ResourceManagerContext';
import {
	getFromSessionStorage,
	removeFromSessionStorage,
	setInSessionStorage,
} from 'javascript/sf/Storage';

export const MACHINE_FETCH_CONFIG = {
	storageKey: 'lastMachineDataFetchTime',
	debounceTime: 5000,
	pollingInterval: 120000,
};

export const canFetchMachinesNow = (): boolean => {
	const currentTime = Date.now();
	const lastMachineDataFetchTime =
		Number(getFromSessionStorage<number>(MACHINE_FETCH_CONFIG.storageKey)) ||
		currentTime - MACHINE_FETCH_CONFIG.pollingInterval;
	return (
		currentTime - lastMachineDataFetchTime > MACHINE_FETCH_CONFIG.pollingInterval - 100
	);
};

export const clearMachineDataFetchTime = () => {
	removeFromSessionStorage(MACHINE_FETCH_CONFIG.storageKey);
};

export const fetchMachinesFromNetwork = async (
	url: string,
	desktopIds: string[],
	hasLoggedIn: () => Promise<boolean>
): Promise<(Machine | MachineError)[]> => {
	const isUserLoggedIn = await hasLoggedIn();
	if (!isUserLoggedIn) {
		return [];
	}
	try {
		const machinesStatus = await fetchMachineList(url, desktopIds);
		setInSessionStorage(MACHINE_FETCH_CONFIG.storageKey, Date.now());
		return machinesStatus?.machines || [];
	} catch (error) {
		return [];
	}
};

export const getQueryableMachineIds = (resources?: any[]): string[] =>
	resources
		?.filter(({ canquerymachinestate }) => canquerymachinestate)
		.map(({ id }) => id) || [];
